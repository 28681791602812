'use strict';

const STORE_RESULTS_CONTAINER_SELECTOR = '[data-js="store-locator-results"]';
var isTriggeredByMarker = false;

if ($('[data-js="map-canvas"]').length > 0) {
    var customGoogleMap = require('./customMap');
}

/**
 * appends params to a url
 * @param {string} data - store data to load into map
 * @param {boolean} isMyLocationSearch - if the search is triggered with My Location button
 */
function renderMap(data, isMyLocationSearch) {
    updateStoresResults(data, isMyLocationSearch);
    if ($('.storelocator__search').find('input[name=isCheckout]').val() === 'true') {
        // google maps checkout events
        $('[data-js=map-store-selection]').off('click').on('click', function (e) {
            e.preventDefault();
            var storeId = $(this).closest('.store-details').data('store-id');
            $('[data-js=selectedStore]').val(storeId);
            var storeName = $('[data-store-id="' + storeId + '"] > div.store__name').text();
            var storeAddress = $('[data-store-id="' + storeId + '"] > address > .address').text();
            $('[data-js=selectedStore]').attr('data-store-name', storeName);
            $('[data-js=selectedStore]').attr('data-store-address', storeAddress);

            $('[data-js=selected-store-info] > [data-js=store-name]').text(storeName);
            $('[data-js=selected-store-info] > [data-js=store-address]').text(storeAddress);
            $('[data-js="modal-close"]').trigger('click');
            $('[data-js=checkout-shipping-store-selection]').addClass('is-selected');
            $('.js-edit-store').removeClass('is-hidden');
            $('.js-select-store').addClass('is-hidden');
        });
    }
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 * @param {boolean} isMyLocationSearch - if the search is triggered with My Location button
 */
function updateStoresResults(data, isMyLocationSearch) {
    var $mapDiv = $('[data-js="map-canvas"]');
    var hasResults = data.stores.length > 0;

    if (!hasResults) {
        $('[data-js="store-locator-no-results"]').show();
    } else {
        $('[data-js="store-locator-no-results"]').hide();
    }

    $(STORE_RESULTS_CONTAINER_SELECTOR).empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey);

    $mapDiv.attr('data-locations', data.locations);

    if ($mapDiv.data('has-google-api')) {
        // eslint-disable-next-line block-scoped-var
        customGoogleMap.initGoogleMap(handleMarkerTriggeredStatus, true, isMyLocationSearch);
    } else {
        $('[data-js="store-locator-no-apiKey"]').show();
    }

    if (data.storesResultsHtml) {
        $(STORE_RESULTS_CONTAINER_SELECTOR).append(data.storesResultsHtml);
    }
}

/**
 *
 * @param {string} storeId - marker store ID, not necessary value, just need to follow up this structure
 * @param {string} postalCode - postal code, not necessary value, just need to follow up this structure
 * @param {boolean} isTriggeredFromMarker - flag to show that store selection was triggered from marker
 */
function handleMarkerTriggeredStatus(storeId, postalCode, isTriggeredFromMarker) {
    isTriggeredByMarker = isTriggeredFromMarker;
}

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function storeSearch() {
    var $form = $('.store-locator');
    var url = $form.attr('action');
    var isHearingAid = $('[data-js="store-locator-checkboxes"] input#hearingAid').prop('checked');
    var isGlasses = $('[data-js="store-locator-checkboxes"] input#glasses').prop('checked');
    var isLenses = $('[data-js="store-locator-checkboxes"] input#lenses').prop('checked');
    var urlParams = {
        isHearingAid: isHearingAid,
        isGlasses: isGlasses,
        isLenses: isLenses
    };

    var $postalCodeInput = $form.find('[name="postalCode"]');
    var postalCodeValue = $postalCodeInput.val().trim();

    if (postalCodeValue.length === 0) {
        $(STORE_RESULTS_CONTAINER_SELECTOR).find('[data-js="search-error"]').remove();
        $(STORE_RESULTS_CONTAINER_SELECTOR).prepend('<p class="text-is-center" data-js="search-error">' + $(STORE_RESULTS_CONTAINER_SELECTOR).data('errors').noSearchQuery + '</p>');
        return;
    }
    var APISearchQuery = $('[data-postal=api-search-result]').val();
    var postalCode = APISearchQuery || postalCodeValue;
    var payload = $form.is('form') ? $form.serialize() : { postalCode: postalCode };

    if (!payload.postalCode) {
        payload.radius = 550; // to show the whole map
    }

    url = appendToUrl(url, urlParams);

    $.ajax({
        url: url,
        type: $form.attr('method'),
        data: payload,
        dataType: 'json',
        success: function (data) {
            // gtm event start
            $(document).trigger('gtm:storeFinder', {
                location: postalCode,
                activityType: 'search'
            });
            // gtm event end
            renderMap(data);
            // Remove focus from the input on mobile
            $postalCodeInput.blur();
        }
    });
    return false;
}

module.exports.storeSearch = storeSearch;

module.exports = {
    init: function () {
        // if it is the checkout page - do not initialize the map, it will be initialized when opening store selection modal
        if ($('.storelocator__search').find('input[name=isCheckout]').val() === 'true') {
            $('.js-select-store').on('click', function () {
                // eslint-disable-next-line block-scoped-var
                customGoogleMap.initGoogleMap(handleMarkerTriggeredStatus);
                customGoogleMap.initAutocomplete();
            });
            return;
        }

        if ($('[data-js="map-canvas"]').data('has-google-api')) {
            // eslint-disable-next-line block-scoped-var
            customGoogleMap.initGoogleMap(handleMarkerTriggeredStatus);
            customGoogleMap.initAutocomplete();
        } else {
            $('[data-js="store-locator-no-apiKey"]').show();
        }

        if (!$(STORE_RESULTS_CONTAINER_SELECTOR).data('has-results')) {
            $('[data-js="store-locator-no-results"]').show();
        }

        // handle preselected make appointment store click on store finder page from storefinder PD component
        var urlParams = new URLSearchParams(window.location.search);
        var storefinderInputFieldValue = urlParams.get('autocompleteInputField');
        if (storefinderInputFieldValue) {
            var $searchStoreLocationInput = $('[data-js="search-store-location"]');
            $searchStoreLocationInput.attr('value', storefinderInputFieldValue);
            storeSearch();
        }
    },

    detectLocation: function () {
        // clicking on detect location.
        $('[data-js="detect-location"]').off('click').on('click', function (e) {
            e.preventDefault();

            $('[name="postalCode"]').val('');
            $('[data-postal=api-search-result]').val('');

            var $myLocationBtn = $(this);
            var $detectLocationButton = $('[data-js="detect-location"]');
            var url = $detectLocationButton.data('action');
            var radius = '30';
            var isHearingAid = $('[data-js="store-locator-checkboxes"] input#hearingAid').prop('checked');
            var isGlasses = $('[data-js="store-locator-checkboxes"] input#glasses').prop('checked');
            var isLenses = $('[data-js="store-locator-checkboxes"] input#lenses').prop('checked');
            var urlParams = {
                radius: radius,
                isHearingAid: isHearingAid,
                isGlasses: isGlasses,
                isLenses: isLenses,
                lat: $myLocationBtn.data('mylocation')['lat'],
                long: $myLocationBtn.data('mylocation')['long']
            };

            if (!navigator.geolocation) {
                url = appendToUrl(url, urlParams);
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        renderMap(data, true);
                    }
                });
            }

            navigator.geolocation.getCurrentPosition(function (position) {
                if (position.coords) {
                    urlParams.lat = position.coords.latitude;
                    urlParams.long = position.coords.longitude;
                }

                url = appendToUrl(url, urlParams);
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        renderMap(data, true);
                    }
                });
            }, function () {
                url = appendToUrl(url, urlParams);
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        renderMap(data, true);
                    }
                });
            });
        });
    },

    search: function () {
        $('[data-js="store-locator-container"] form.store-locator').submit(function (e) {
            e.preventDefault();
            storeSearch();
        });
        $('[data-js="search-store-location"]').keypress(function (e) {
            if (e.which === 13) {
                e.preventDefault();
                $('[data-postal=api-search-result]').val('');
                storeSearch();
            }
        });
    },

    changeRadius: function () {
        $('[data-js="store-locator-container"] [data-js="radius"]').change(function () {
            var radius = $(this).val();
            var searchKeys = $(STORE_RESULTS_CONTAINER_SELECTOR).data('search-key');
            var url = $(this).data('action-url');
            var isHearingAid = $('[data-js="store-locator-checkboxes"] input#hearingAid').prop('checked');
            var isGlasses = $('[data-js="store-locator-checkboxes"] input#glasses').prop('checked');
            var isLenses = $('[data-js="store-locator-checkboxes"] input#lenses').prop('checked');
            var urlParams = {
                radius: radius,
                isHearingAid: isHearingAid,
                isGlasses: isGlasses,
                isLenses: isLenses
            };

            if (searchKeys.postalCode) {
                urlParams.postalCode = searchKeys.postalCode;
            } else if (searchKeys.lat && searchKeys.long) {
                urlParams.lat = searchKeys.lat;
                urlParams.long = searchKeys.long;
            }

            url = appendToUrl(url, urlParams);
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    renderMap(data);
                }
            });
        });
    },
    selectStore: function () {
        $('[data-js="store-locator-container"]').off('click', '[data-js="select-store"]').on('click', '[data-js="select-store"]', (function (e) {
            e.preventDefault();

            $(this).siblings().removeClass('focused');
            $(this).addClass('focused');

            // eslint-disable-next-line block-scoped-var
            if (customGoogleMap) {
                // eslint-disable-next-line block-scoped-var
                customGoogleMap.selectMarker($(this).attr('id'), isTriggeredByMarker);
                isTriggeredByMarker = false;
            }

            var selectedStore = $(':checked', '[data-js="results-card"] [data-js="store-locator-results"]');
            var data = {
                storeID: selectedStore.val(),
                searchRadius: $('[data-js="radius"]').val(),
                searchPostalCode: $(STORE_RESULTS_CONTAINER_SELECTOR).data('search-key').postalCode,
                storeDetailsHtml: selectedStore.siblings('label').find('[data-js="store-details"]').html(),
                event: e
            };

            $('body').trigger('store:selected', data);
        }));
    },
    chooseCheckboxes: function () {
        $('[data-js="store-locator-checkboxes"] input[type=checkbox]').off('click').on('click', function () {
            var url = $(this).data('action-url');
            var radius = $(STORE_RESULTS_CONTAINER_SELECTOR).data('radius');
            var searchKeys = $(STORE_RESULTS_CONTAINER_SELECTOR).data('search-key');
            var isHearingAid = $('[data-js="store-locator-checkboxes"] input#hearingAid').prop('checked');
            var isGlasses = $('[data-js="store-locator-checkboxes"] input#glasses').prop('checked');
            var isLenses = $('[data-js="store-locator-checkboxes"] input#lenses').prop('checked');
            var urlParams = {
                radius: radius,
                isHearingAid: isHearingAid,
                isGlasses: isGlasses,
                isLenses: isLenses
            };

            if (searchKeys.postalCode) {
                urlParams.postalCode = searchKeys.postalCode;
            } else if (searchKeys.lat && searchKeys.long) {
                urlParams.lat = searchKeys.lat;
                urlParams.long = searchKeys.long;
            }

            url = appendToUrl(url, urlParams);
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    renderMap(data);
                }
            });
        });
    },
    showStore: function () {
        $('[data-js="store-locator-container"]').off('click', '[data-js="store-details-show-store"]').on('click', '[data-js="store-details-show-store"]', function (e) {
            // gtm event start
            $(document).trigger('gtm:storeFinder', {
                location: $(this).data('store-name'),
                activityType: 'chose'
            });
            // gtm event end
            e.stopImmediatePropagation();
        });
    },
    makeAppointment: function () {
        $('[data-js="store-locator-container"]').on('click', '[data-js="store-details-make-appointment"]', function (e) {
            e.stopImmediatePropagation();
        });
    },
    callStore: function () {
        $('[data-js="store-locator-container"]').off('click', '[data-js="store-details-call-store"]').on('click', '[data-js="store-details-call-store"]', function (e) {
            e.stopImmediatePropagation();
        });
    }
};
